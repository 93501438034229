
import { defineComponent, computed } from "vue";
import ObservationRecap from "@/components/customs/ObservationRecap.vue"
import { useStore } from "vuex";
import RadarListeRecap from "@/components/networks/radar/form/liste/RadarListeRecap.vue"

export default defineComponent({
  name: "radar-recap",
  props: {
     data : {type:Object, default:null}
  },
  setup(props){
    const store   = useStore();

    const hasObservation =computed(() => {
      return props.data.radar.observation != "" && props.data.radar.observation != undefined && props.data.radar.observation != null
    })
    /*
     * Permet de recuperer une entité spécifique
     */
    const getEntGeoById = ((id: string) => {
      return store.getters.entgeos.find( entgeo => {return entgeo.id == id})
    })

    /*
    * Permet de recuperer une entité spécifique
    */
    const showEntGeoByDetails = ((id: string) => {
      let entgeo = getEntGeoById(id)
      let nomVille = entgeo.nom 
      let province = entgeo.parent.parent.nom 
      let nomPays = "GABON" 
      return nomVille+", "+province+", "+nomPays
    })

    return { showEntGeoByDetails,hasObservation }
  },
  components: {ObservationRecap,RadarListeRecap},
});
